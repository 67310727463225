import {
  FILTER_PRODUCTS_FETCHED,
  FILTER_CUSTOMERS_FETCHED,
  FILTER_PRODUCTS_FETCH_FAILED,
  FILTER_CUSTOMERS_FETCH_FAILED,
  FILTER_TRADING_SYSTEMS_FETCHED,
  FILTER_TRADING_SYSTEMS_FETCH_FAILED,
  FILTER_CUSTOMER_SYSTEMS_FETCHED,
  FILTER_CUSTOMER_SYSTEMS_FETCH_FAILED,
  FILTER_USER_SYSTEMS_FETCHED,
  FILTER_USER_SYSTEMS_FETCH_FAILED,
  FILTER_CUSTOMER_SYSTEMS_AND_USER_SYSTEMS_FETCHED,
  FILTER_CUSTOMER_SYSTEMS_AND_USER_SYSTEMS_FETCH_FAILED,
} from './filters.actions';
import { FiltersState, initialFiltersState } from './filters.model';
import { Actions } from 'store/actions';
import { patchObject } from 'utils/objects/objects';

export const filtersReducer = (state: FiltersState = initialFiltersState, action: Actions): FiltersState => {
  switch (action.type) {
    case FILTER_PRODUCTS_FETCHED:
      return patchObject(state, 'products', patchObject(state.products, action.exchange, action.response));
    case FILTER_PRODUCTS_FETCH_FAILED:
      return patchObject(
        state,
        'products',
        patchObject(state.products, action.exchange, { options: [], error: action.error }),
      );
    case FILTER_CUSTOMERS_FETCHED:
      return patchObject(state, 'customers', patchObject(state.customers, action.exchange, action.response));
    case FILTER_CUSTOMERS_FETCH_FAILED:
      return patchObject(
        state,
        'customers',
        patchObject(state.customers, action.exchange, { options: [], error: action.error }),
      );
    case FILTER_TRADING_SYSTEMS_FETCHED:
      return {
        ...state,
        tradingSystems: {
          ...state.tradingSystems,
          [action.exchange]: {
            ...state.tradingSystems[action.exchange],
            [action.customer]: action.response,
          },
        },
      };
    case FILTER_TRADING_SYSTEMS_FETCH_FAILED:
      return {
        ...state,
        tradingSystems: {
          ...state.tradingSystems,
          [action.exchange]: {
            ...state.tradingSystems[action.exchange],
            [action.customer]: { options: [], error: action.error },
          },
        },
      };
    case FILTER_CUSTOMER_SYSTEMS_FETCHED:
      return {
        ...state,
        customerSystems: {
          ...state.customerSystems,
          [action.exchange]: {
            ...state.customerSystems[action.exchange],
            [action.customer]: {
              ...state.customerSystems[action.exchange]?.[action.customer],
              [action.tradingSystem]: action.response,
            },
          },
        },
      };
    case FILTER_CUSTOMER_SYSTEMS_FETCH_FAILED:
      return {
        ...state,
        customerSystems: {
          ...state.customerSystems,
          [action.exchange]: {
            ...state.customerSystems[action.exchange],
            [action.customer]: {
              ...state.customerSystems[action.exchange][action.customer],
              [action.tradingSystem]: { options: [], error: action.error },
            },
          },
        },
      };
    case FILTER_USER_SYSTEMS_FETCHED:
      return {
        ...state,
        userSystems: {
          ...state.userSystems,
          [action.exchange]: {
            ...state.userSystems[action.exchange],
            [action.customer]: {
              ...state.userSystems[action.exchange]?.[action.customer],
              [action.tradingSystem]: {
                ...state.userSystems[action.exchange]?.[action.customer]?.[action.tradingSystem],
                [action.customerSystemId]: action.response,
              },
            },
          },
        },
      };

    case FILTER_USER_SYSTEMS_FETCH_FAILED:
      return {
        ...state,
        userSystems: {
          ...state.userSystems,
          [action.exchange]: {
            ...state.userSystems[action.exchange],
            [action.customer]: {
              ...state.userSystems[action.exchange]?.[action.customer],
              [action.tradingSystem]: {
                ...state.userSystems[action.exchange]?.[action.customer]?.[action.tradingSystem],
                [action.customerSystemId]: { options: [], error: action.error },
              },
            },
          },
        },
      };
    case FILTER_CUSTOMER_SYSTEMS_AND_USER_SYSTEMS_FETCHED:
      return {
        ...state,
        customerSystems: {
          ...state.customerSystems,
          [action.exchange]: {
            ...state.customerSystems[action.exchange],
            [action.customer]: {
              ...state.customerSystems[action.exchange]?.[action.customer],
              [action.tradingSystem]: {
                options: action.response.options,
              },
            },
          },
        },
        userSystems: {
          ...state.userSystems,
          [action.exchange]: {
            ...state.userSystems[action.exchange],
            [action.customer]: {
              ...state.userSystems[action.exchange]?.[action.customer],
              [action.tradingSystem]: {
                ...state.userSystems[action.exchange]?.[action.customer]?.[action.tradingSystem],
                defaultCustomerSystemId: { options: action.response.userSystemsOptions },
              },
            },
          },
        },
      };
    case FILTER_CUSTOMER_SYSTEMS_AND_USER_SYSTEMS_FETCH_FAILED:
      return {
        ...state,
        customerSystems: {
          ...state.customerSystems,
          [action.exchange]: {
            ...state.customerSystems[action.exchange],
            [action.customer]: {
              ...state.customerSystems[action.exchange][action.customer],
              [action.tradingSystem]: { options: [], error: action.error },
            },
          },
        },
        userSystems: {
          ...state.userSystems,
          [action.exchange]: {
            ...state.userSystems[action.exchange],
            [action.customer]: {
              ...state.userSystems[action.exchange]?.[action.customer],
              [action.tradingSystem]: {
                ...state.userSystems[action.exchange]?.[action.customer]?.[action.tradingSystem],
                defaultCustomerSystemId: { options: [], error: action.error },
              },
            },
          },
        },
      };
    default:
      return state;
  }
};
