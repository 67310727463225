import queryString from 'query-string';
import { map, Observable } from 'rxjs';
import { CustomerSystemFilterOptionsResponse, FilterOptionsResponse } from 'models/Responses/FilterOptionsResponse';
import { HttpClient } from 'utils/http/httpClient.models';
import { isDefinedAndNotEmpty } from 'utils/string/string';

export type FilterOptionType =
  | 'Products'
  | 'Exchanges'
  | 'Customers'
  | 'Accounts'
  | 'Trading-systems'
  | 'Customer-systems'
  | 'User-systems';

export interface FiltersApi {
  getFilterOptions(
    exchange: string,
    type: FilterOptionType,
    queryParams?: Record<string, string | undefined>,
  ): Observable<FilterOptionsResponse>;
  getCustomerSystemsAndUserSystemsFilterOptions(
    exchange: string,
    queryParams?: Record<string, string | undefined>,
  ): Observable<CustomerSystemFilterOptionsResponse>;
}

export const filtersApiConnector = (httpClient: HttpClient): FiltersApi => ({
  getFilterOptions(
    exchange: string,
    type: FilterOptionType,
    queryParams?: Record<string, string>,
  ): Observable<FilterOptionsResponse> {
    const params =
      queryParams && isDefinedAndNotEmpty(queryString.stringify(queryParams))
        ? `?${queryString.stringify(queryParams)}`
        : '';
    return httpClient
      .getJSON<FilterOptionsResponse>({ url: `/api/v1/${exchange}/filters/${type.toLowerCase()}${params}` })
      .pipe(map(({ payload }) => payload));
  },
  getCustomerSystemsAndUserSystemsFilterOptions(
    exchange: string,
    queryParams?: Record<string, string>,
  ): Observable<CustomerSystemFilterOptionsResponse> {
    const params =
      queryParams && isDefinedAndNotEmpty(queryString.stringify(queryParams))
        ? `?${queryString.stringify(queryParams)}`
        : '';
    return httpClient
      .getJSON<CustomerSystemFilterOptionsResponse>({
        url: `/api/v1/${exchange}/filters/customer-systems-with-user-systems/${params}`,
      })
      .pipe(map(({ payload }) => payload));
  },
});
